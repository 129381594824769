import Vue from "vue"
import i18n from "@/libs/i18n"
import { getLocaleKey, getTranslation } from "@/shared/helpers"
import { ACTIONS, SUBJECTS } from "@/libs/acl/config"
import { can } from "@/libs/acl/ability"

export default class Shared {
  static resolveUserStatusVariant = status => {
    const array = [
      {
        id: 1,
        value: 'warning',
        status: 'UnConfirmed',
        name: i18n.t("userStatusVariants.unconfirmed"),
      },
      {
        id: 2,
        value: 'success',
        status: 'Active',
        name: i18n.t("userStatusVariants.active"),
      },
      {
        id: 3,
        value: 'danger',
        status: 'Deleted',
        name: i18n.t("userStatusVariants.deleted"),
      },
    ]

    return array.find(x => x.status === status) ? array.find(x => x.status === status) : {
      id: 0,
      value: 'warning',
      name: i18n.t("userStatusVariants.unconfirmed"),
    }
  }

  static resolveAdminStatusVariant = status => {
    const array = [
      {
        id: 0,
        value: 'warning',
        status: 'UnConfirmed',
        name: i18n.t("userStatusVariants.unconfirmed"),
      },
      {
        id: 2,
        value: 'success',
        status: 'Active',
        name: i18n.t("userStatusVariants.active"),
      },
      {
        id: 3,
        value: 'danger',
        status: 'Deleted',
        name: i18n.t("userStatusVariants.deleted"),
      },
    ]

    return array.find(x => x.status === status) ? array.find(x => x.status === status) : {
      id: 0,
      value: 'warning',
      name: i18n.t("userStatusVariants.unconfirmed"),
    }
    // if (status === 1) return 'warning'
    // if (status === 2) return 'success'
    // if (status === 3) return 'danger'
    // return 'primary'
  }

  static resolveAdminRoleVariant = role => {
    const array = [
      {
        id: 1,
        value: 'SuperAdmin',
        name: i18n.t("adminRoleVariant.superAdmin"),
      },
    ]

    return array.find(x => x.value === role)
    // if (status === 1) return 'warning'
    // if (status === 2) return 'success'
    // if (status === 3) return 'danger'
    // return 'primary'
  }

  static resolveUserRoleVariant = status => {
    const array = [
      {
        id: 1,
        value: 'Teacher',
        name: i18n.t("userRoleVariant.teacher"),
      },
      {
        id: 2,
        value: 'TeacherContributor',
        name: i18n.t("userRoleVariant.teacherContributor"),
      },
      {
        id: 3,
        value: 'Student',
        name: i18n.t("userRoleVariant.student"),
      },
    ]

    return array.find(x => x.id === status)
    // if (status === 1) return 'warning'
    // if (status === 2) return 'success'
    // if (status === 3) return 'danger'
    // return 'primary'
  }

  static getAdminRoles = () => [
    {
      id: 1,
      value: 'SuperAdmin',
      name: i18n.t("adminRoleVariant.superAdmin"),
    },
  ]

  static getUserRoles = () => [
    {
      id: 1,
      value: 'Teacher',
      name: i18n.t("userRoleVariant.teacher"),
    },
    {
      id: 2,
      value: 'TeacherContributor',
      name: i18n.t("userRoleVariant.teacherContributor"),
    },
    {
      id: 3,
      value: 'Student',
      name: i18n.t("userRoleVariant.student"),
    },
  ]

  static getAdminStatuses = () => [
    {
      id: 2,
      value: 'success',
      name: i18n.t("userStatusVariants.active"),
    },
    {
      id: 3,
      value: 'danger',
      name: i18n.t("userStatusVariants.deleted"),
    },
  ]

  static getUserStatuses = () => [
    {
      id: 1,
      value: 'warning',
      name: i18n.t("userStatusVariants.unconfirmed"),
  },
    {
      id: 2,
      value: 'success',
      name: i18n.t("userStatusVariants.active"),
    },
    {
      id: 3,
      value: 'danger',
      name: i18n.t("userStatusVariants.deleted"),
    },
  ]

  static getAdminRolesForFilter = () => [
    {
      id: 1,
      value: 'SuperAdmin',
      text: i18n.t("adminRoleVariant.superAdmin"),
    },
  ]

  static getAdminRolesForSelect = () => [
    {
      id: 1,
      value: 'SuperAdmin',
      label: i18n.t("adminRoleVariant.superAdmin"),
    },
  ]

  static getAdminStatusesForFilter = () => [
    {
      value: 2,
      status: 'Active',
      text: i18n.t("userStatusVariants.active"),
    },
    {
      value: 3,
      status: 'Deleted',
      text: i18n.t("userStatusVariants.deleted"),
    },
  ]

  static getAdminStatusesForSelect = () => [
    {
      value: 2,
      label: i18n.t("userStatusVariants.active"),
    },
    {
      value: 3,
      label: i18n.t("userStatusVariants.deleted"),
    },
  ]

  static getUserStatusesForFilter = () => [
    {
      value: 1,
      status: 'UnConfirmed',
      text: i18n.t("userStatusVariants.unconfirmed"),
    },
    {
      value: 2,
      status: 'Active',
      text: i18n.t("userStatusVariants.active"),
    },
    {
      value: 3,
      status: 'Deleted',
      text: i18n.t("userStatusVariants.deleted"),
    },
  ]

  static getUserTypeForFilter = () => [
    {
      value: 'Student',
      text: i18n.t("userRoleVariant.student"),
    },
    {
      value: 'Teacher',
      text: i18n.t("userRoleVariant.teacher"),
    },
    {
      value: 'TeacherContributor',
      text: i18n.t("userRoleVariant.teacherContributor"),
    },
    {
      value: 'Other',
      text: i18n.t("Other"),
    },
  ]

  static getRegisterTypes = () => [
    {
      value: "Email",
      text: i18n.t("fields.email"),
    },
    {
      value: 'Phone',
      text: i18n.t("fields.phone"),
    },
  ]

  static statusUpdate = () => [
    { value: 'draft', text: i18n.t('boxTitle.draft') },
    { value: 'unpublished', text: i18n.t('boxTitle.unpublished') },
    ...(can(ACTIONS.PUBLISH, SUBJECTS.ARTICLE) ? [{ value: 'published', text: i18n.t('boxTitle.published') }] : [])
  ];
  static statusTypes = (lng) => [
    {
      value: 'draft',
      text: getTranslation('boxTitle.draft', lng),
    },
    {
      value: 'unpublished',
      text: getTranslation('boxTitle.unpublished', lng),
    },
    {
      value: 'published',
      text: getTranslation('boxTitle.published', lng),
    }
  ]

  static historyUpdate = () => [
    {
      value: 1,
      text: 'option 1',
    },
    {
      value: 2,
      text: 'option 2',
    },
    {
      value: 3,
      text: 'option 3',
    },
  ]

  static placeUpdate = () => [
    {
      value: 1,
      text: 'option 1',
    },
    {
      value: 2,
      text: 'option 2',
    },
    {
      value: 3,
      text: 'option 3',
    },
  ]

  static landUpdate = () => [
    {
      value: 1,
      text: 'option 1',
    },
    {
      value: 2,
      text: 'option 2',
    },
    {
      value: 3,
      text: 'option 3',
    },
  ]

  static historyLandUpdate = () => [
    {
      value: 1,
      text: 'option 1',
    },
    {
      value: 2,
      text: 'option 2',
    },
    {
      value: 3,
      text: 'option 3',
    },
  ]

  static tagsUpdate = () => [
    {
      value: 1,
      text: i18n.t("boxTitle.addTags"),
    },
    {
      value: 2,
      text: 'option 2',
    },
    {
      value: 3,
      text: 'option 3',
    },
  ]

  static lessonUpdate = () => [
    {
      value: 1,
      text: 'option 1',
    },
    {
      value: 2,
      text: 'option 2',
    },
    {
      value: 3,
      text: 'option 3',
    },
  ]

  static themeUpdate = () => [
    {
      value: 1,
      text: 'option 1',
    },
    {
      value: 2,
      text: 'option 2',
    },
    {
      value: 3,
      text: 'option 3',
    },
  ]

  static topicsUpdate = () => [
    {
      value: 1,
      text: 'option 1',
    },
    {
      value: 2,
      text: 'option 2',
    },
    {
      value: 3,
      text: 'option 3',
    },
  ]

  static zoomUpdate = () => [
    {
      value: 1,
      text: i18n.t("fields.select"),
    },
    {
      value: 2,
      text: '1',
    },
    {
      value: 3,
      text: '2',
    },
    {
      value: 4,
      text: '3',
    },
    {
      value: 5,
      text: '4',
    },
    {
      value: 6,
      text: '5',
    },
    {
      value: 7,
      text: '6',
    },
  ]

  static getUserRolesForSelect = withOther => {
    const data = [
      {
        id: 1,
        value: 'Teacher',
        label: i18n.t("userRoleVariant.teacher"),
      },
      {
        id: 2,
        value: 'TeacherContributor',
        label: i18n.t("userRoleVariant.teacherContributor"),
      },
      {
        id: 3,
        value: 'Student',
        label: i18n.t("userRoleVariant.student"),
      },
    ]

    if (withOther) {
      data.push({
        id: 4,
        value: 'Other',
        label: i18n.t("Other"),
      })
    }
    return data
  }

  static getUserRolesForFilter = () => [
    {
      id: 1,
      value: 'Teacher',
      text: i18n.t("userRoleVariant.teacher"),
    },
    {
      id: 2,
      value: 'TeacherContributor',
      text: i18n.t("userRoleVariant.teacherContributor"),
    },
    {
      id: 3,
      value: 'Student',
      text: i18n.t("userRoleVariant.student"),
    },
  ]

  static getUserStatusesForSelect = () => [
    {
      value: 1,
      label: i18n.t("userStatusVariants.unconfirmed"),
    },
    {
      value: 2,
      label: i18n.t("userStatusVariants.active"),
    },
    {
      value: 3,
      label: i18n.t("userStatusVariants.deleted"),
    },
  ]

  static getStatus = () => [
    {
      value: 1,
      status: 'Active',
      text: i18n.t("fields.active"),
    },
    {
      value: 2,
      status: 'Inactive',
      text: i18n.t("fields.inactive"),
    },
  ]

  static getGenderForFilter = () => [
    {
      id: 1,
      value: 'Male',
      text: i18n.t("fields.Male"),
    },
    {
      id: 2,
      value: 'Female',
      text: i18n.t("fields.Female"),
    },
  ]

  static getGenderForSelect = () => [
    {
      id: 1,
      value: 'Male',
      label: i18n.t("fields.Male"),
    },
    {
      id: 2,
      value: 'Female',
      label: i18n.t("fields.Female"),
    },
  ]

  static getStylingTypes = () => [
    {
      id: 0,
      value: 'pin',
      text: i18n.t("stylingTypes.pin"),
    },
    {
      id: 5,
      value: 'country',
      text: i18n.t("stylingTypes.country"),
    },
    {
      id: 1,
      value: 'river',
      text: i18n.t("stylingTypes.river"),
    },
    {
      id: 2,
      value: 'lake',
      text: i18n.t("stylingTypes.lake"),
    },
    {
      id: 3,
      value: 'sea',
      text: i18n.t("stylingTypes.sea"),
    },
    {
      id: 4,
      value: 'ocean',
      text: i18n.t("stylingTypes.ocean"),
    },
    {
      id: 6,
      value: 'other',
      text: i18n.t("stylingTypes.other"),
    },
  ]

  static getStyleTheme = () => [
    {
      id: 1,
      value: 'configured',
      text: 'Configured',
    },
  ]

  static getTypeOfArticel = () => [
    {
      id: null,
      value: null,
      title: i18n.t("fields.all"),
    },
    {
      id: 0,
      value: 'Mandatory',
      title: i18n.t("fields.main"),
    },
    {
      id: 1,
      value: 'Additional',
      title: i18n.t("boxTitle.Additional"),
    },
  ]

  static getTypeArticle = () => [
    {
      id: 0,
      value: 'Mandatory',
      title: i18n.t("fields.main"),
    },
    {
      id: 1,
      value: 'Additional',
      title: i18n.t("boxTitle.Additional"),
    },
  ]

  static getBorderStyle = () => [
    {
      id: 1,
      value: 'solid',
      text: 'Solid',
    },
    {
      id: 2,
      value: 'dashed',
      text: 'Dashed',
    },
    {
      id: 3,
      value: 'dotted',
      text: 'Dotted',
    },
  ]

  static resolveEncyclopediaStatusVariant = (status, lng) => {
    const array = [
      {
        id: 1,
        value: 'warning',
        key: 'Draft',
        name: getTranslation('boxTitle.draft', lng),
      },
      {
        id: 2,
        value: 'success',
        key: 'Published',
        name: getTranslation('boxTitle.published', lng),
      },
      {
        id: 3,
        value: 'danger',
        key: 'Unpublished',
        name: getTranslation('boxTitle.unpublished', lng),
      },
    ]

    return array.find(x => x.key === status) || {
      id: 1,
      value: 'warning',
      key: 'Draft',
      name: i18n.t('boxTitle.draft'),
    }
    // if (status === 1) return 'warning'
    // if (status === 2) return 'success'
    // if (status === 3) return 'danger'
    // return 'primary'
  }

  static getMonth = () => [
    {
      id: 1,
      value: 1,
      text: i18n.t("months.january"),
      name: "january",
      daysCount: 31,
    },
    {
      id: 2,
      value: 2,
      text: i18n.t("months.february"),
      name: "february",
      daysCount: 29,
    },
    {
      id: 3,
      value: 3,
      text: i18n.t("months.march"),
      name: "march",
      daysCount: 31,
    },
    {
      id: 4,
      value: 4,
      text: i18n.t("months.april"),
      name: "april",
      daysCount: 30,
    },
    {
      id: 5,
      value: 5,
      text: i18n.t("months.may"),
      name: "may",
      daysCount: 31,
    },
    {
      id: 6,
      value: 6,
      text: i18n.t("months.june"),
      name: "june",
      daysCount: 30,
    },
    {
      id: 7,
      value: 7,
      text: i18n.t("months.july"),
      name: "july",
      daysCount: 31,
    },
    {
      id: 8,
      value: 8,
      text: i18n.t("months.august"),
      name: "august",
      daysCount: 31,
    },
    {
      id: 9,
      value: 9,
      text: i18n.t("months.september"),
      name: "september",
      daysCount: 30,
    },
    {
      id: 10,
      value: 10,
      text: i18n.t("months.october"),
      name: "october",
      daysCount: 31,
    },
    {
      id: 11,
      value: 11,
      text: i18n.t("months.november"),
      name: "november",
      daysCount: 30,
    },
    {
      id: 12,
      value: 12,
      text: i18n.t("months.december"),
      name: "december",
      daysCount: 31,
    },
  ];

  static getDay = (daysCount = 31) => {
    const data = []
    new Array(daysCount).fill().forEach((e, i) => {
      data.push({
        id: (`${i + 1}`).slice(-2),
        value: (`${i + 1}`).slice(-2),
        text: (`${i + 1}`).slice(-2),
      })
    })
    return data
  }

  static getHoure = () => {
    const data = []
    new Array(24).fill().forEach((e, i) => {
      data.push({
        id: (`${i}`).slice(-2).padStart(2, '0'),
        value: (`${i}`).slice(-2).padStart(2, '0'),
        text: (`${i}`).slice(-2).padStart(2, '0'),
      })
    })
    return data
  }

  static getMinute = () => {
    const data = []
    new Array(60).fill().forEach((e, i) => {
      data.push({
        id: (`${i}`).slice(-2).padStart(2, '0'),
        value: (`${i}`).slice(-2).padStart(2, '0'),
        text: (`${i}`).slice(-2).padStart(2, '0'),
      })
    })
    return data
  }

  static getZoom = () => {
    const data = []
    new Array(10).fill().forEach((e, i) => {
      data.push({
        id: i + 1,
        value: i + 1,
        text: i + 1,
      })
    })
    return data
  }

  static getNumbers = () => {
    const data = []
    new Array(10).fill().forEach((e, i) => {
      data.push({
        id: i + 1,
        value: i + 1,
        text: i + 1,
      })
    })
    return data
  }

  static getFontSizes = () => {
    const data = []
    new Array(10).fill().forEach((e, i) => {
      data.push({
        id: i + 17,
        value: i + 17,
        text: i + 17,
      })
    })
    return data
  }

  static getOpacity = () => {
    const data = []
    new Array(11).fill().forEach((e, i) => {
      data.push({
        id: (i) / 10,
        value: (i) / 10,
        text: `${(i) * 10}%`,
      })
    })
    return data
  }

  static colors = () => [
      '#10A378',
      '#1BBF93',
      '#42D3B0',
      '#66CAB2',
      '#4c2a85',
      '#0576F4',
      '#5BAFD8',
      '#95D4F3',
      '#FFB207',
      '#FFCC01',
      '#FFDE5A',
      '#a23e48',
      '#EB572C',
      '#FF6539',
      '#FF8B5D',
      '#7a3b69',
      '#CB6F9C',
      '#EA91BC',
      '#F3BFDA',
      '#F2F2F2',
      '#725752',
      'politry',
    ]

  static colorsCategory = () => [
      '#FFCC01',
      '#FF6539',
      '#66CAB2',
      '#0576F4',
      'politry',
    ]

  static fontWidth = () => [
      {
        id: 'Arial Unicode MS Bold',
        value: 'Arial Unicode MS Bold',
        text: 'Bold',
      },
      {
        id: 'Arial Unicode MS Regular',
        value: 'Arial Unicode MS Regular',
        text: 'Regular',
      },
      // {
      //   id: 'Noto Sans CJK JP Medium',
      //   value: 'Noto Sans CJK JP Medium',
      //   text: 'Medium',
      // },
    ]

  static getArticleAdditionalLabels = () => [
      {
        id: 1,
        value: 'noTitle',
        text: i18n.t('fields.noTitle'),
      },
      {
        id: 2,
        value: 'byAccession',
        text: i18n.t('fields.byAccession'),
      },
      {
        id: 3,
        value: 'byManagement',
        text: i18n.t('fields.byManagement'),
      },
      {
        id: 4,
        value: 'byYearsOfLife',
        text: i18n.t('fields.byYearsOfLife'),
      },
      {
        id: 5,
        value: 'byActivity',
        text: i18n.t('fields.byActivity'),
      },
      {
        id: 6,
        value: 'byCreation',
        text: i18n.t('fields.byCreation'),
      },
    ]
}
