import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"
import VueThreeSixty from 'vue-360'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import 'vue-360/dist/css/style.css'
// Global Components
import './global-components'
// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import localizedMixin from "@/mixins/localizedMixin"
import AbilityPlugin from "@/libs/acl/abilityPlugin"

Sentry.init({
  Vue,
  dsn: "https://eab03d4ddf3642f2b1abd09fc5205709@sentry.mnt.tcp.am/27",
  environment: process.env.VUE_APP_ENVIRONMENT,
  release: `${process.env.VUE_APP_NAME}@${process.env.VUE_APP_VERSION}`,
  debug: process.env.VUE_APP_ENVIRONMENT !== 'production',
  // debug: true,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["http://localhost:8080/", "https://admin.test.bg.cluster.vecto.digital/", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueThreeSixty)
Vue.use(AbilityPlugin)
// Composition API
Vue.use(VueCompositionAPI)

Vue.mixin(localizedMixin)
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
