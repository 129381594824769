import Vue from 'vue'
import ability, { can, setAbilities } from './ability'

const AbilityPlugin = {
    install(Vue) {
        // Make the Ability instance available as $ability
        Vue.prototype.$ability = ability

        // Make the can function available as $can
        Vue.prototype.$can = can

        // Make the setAbilities function available as $setAbilities
        Vue.prototype.$setAbilities = setAbilities
    }
}

export default AbilityPlugin
