import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import dashboard from './routes/dashboard'
import pages from './routes/pages'
import i18n from "@/libs/i18n"
import AdminList from "@/views/pages/admin/admin-list/AdminList.vue"
import { routePermissions } from "@/libs/acl/config"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: { name: 'default'},
    },
    {
     path: '/:lang',
     name: 'default',
     redirect: { name: 'my-account'},
      component: {
        render(c) {
          return c('router-view')
        },
      },
    },
    ...dashboard,
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = isUserLoggedIn() // Check if the user is logged in
  const isRequireAuth = to.matched.some(record => record.meta.requireAuth)
  // 1. Authentication Check
  if (isRequireAuth && !isAuthenticated) {
    return next({ name: 'auth-login', params: { lang: 'hy' } })
  }

  // 2. Permission Check (Only if authenticated)
  if (isAuthenticated) {
    const requiredPermissions = routePermissions[to.matched[0].path]
    if (requiredPermissions.length > 0) {
      // Check if the user has the required permissions for the route
      const hasPermission = requiredPermissions.some(permission => getUserData()?.permissions?.includes(permission))
      // If the user doesn't have permission, redirect them to the first available route
      if (!hasPermission) {
        // If no available route, redirect to a 404 page
        return next({ name: 'my-account', params: { lang: to.params.lang } })
      }
    }
  }

  // 3. Language Check
  const lang = to.params.lang
  if (!['hy', 'en'].includes(lang)) return next('hy')
  if (i18n.locale !== lang) {
    i18n.locale = lang
  }

  // Proceed to the route
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
