<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >

    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>

import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  // mounted() {
  //   // Initialize the MutationObserver when the component is mounted
  //   setTimeout(() => {
  //     console.log('RUNNED')
  //     this.initMutationObserver()
  //   }, 5000)
  // },
  beforeCreate() {
    window.iconCache = {}

    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  // updated() {
  //   // Reapply the observer when the component updates
  //   this.reapplyMutationObserver()
  // },
  // methods: {
  //   initMutationObserver() {
  //     // Function to revert any modified attributes or content
  //     const revertChanges = mutations => {
  //       setTimeout(() => {
  //         mutations.forEach(mutation => {
  //           if (mutation.type === 'attributes') {
  //             const target = mutation.target
  //             const attr = mutation.attributeName
  //
  //             // Revert disabled and readonly attributes
  //             if (attr === 'disabled' || attr === 'readonly') {
  //               target.setAttribute(attr, true)
  //             }
  //           }
  //         })
  //       }, 0)
  //     }
  //
  //     // Create a new MutationObserver
  //     this.observer = new MutationObserver(revertChanges)
  //
  //     // Start observing the specific elements
  //     const elementsToObserve = this.$el.querySelectorAll('input, button')
  //     elementsToObserve.forEach(element => {
  //       this.observer.observe(element, {
  //         attributes: true, // Detect attribute changes
  //         subtree: false, // Don't observe child elements
  //       })
  //     })
  //
  //     console.log('Observer is now monitoring selected elements for changes.')
  //   },
  //   reapplyMutationObserver() {
  //     // Disconnect any existing observer before reapplying (to avoid duplicate observers)
  //     if (this.observer) {
  //       this.observer.disconnect()
  //     }
  //
  //     // Select the elements you want to observe
  //     const elementsToObserve = this.$el.querySelectorAll('input, button')
  //
  //     // Reapply the observer to the selected elements
  //     elementsToObserve.forEach(element => {
  //       this.observer.observe(element, {
  //         attributes: true, // Detect attribute changes
  //         subtree: false, // Don't observe child elements
  //       })
  //     })
  //
  //     console.log('Observer re-applied to selected elements after update.')
  //   },
  // },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
}
</script>
<style>
/* width */
*::-webkit-scrollbar {
  width:20px;
  height:20px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #2c476c;
  padding: 0 4px;
  border-top: 7px solid transparent;
  border-bottom:7px solid transparent;
  border-right: 7px solid transparent;
  border-left:7px solid transparent;
  background-clip: padding-box;
  border-radius: 10px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #2c476c;
  border-radius: 10px;
}
</style>
