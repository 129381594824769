import IdentityAPI from '@/server/identityAPI'

export default class IdentityDataService {
    static signIn = body => IdentityAPI.post(`/api/Admin/SignIn`, body)

    // Current User
    static getCurrent = () => IdentityAPI.get('/api/Admin')

    static getCurrentPermissions = () => IdentityAPI.get('/api/Admin/Role/Permissions')

    static getRolesForCurrentUser = (body = {
        page: 1,
        perPage: 100,
        sort: [
            {
                field: "id",
                type: "asc",
            },
        ],
    }) => IdentityAPI.post('/api/Role/Admin/DataProvider', body)

    static updateCurrent = body => IdentityAPI.put('/api/Admin', body)

    static uploadAvatar = body => IdentityAPI.put('/api/Admin/Avatar', body)

    static deleteCurrent = () => IdentityAPI.delete('/api/Admin')

    // Identity
    static getAdminsList = body => IdentityAPI.post(`/api/Admin/DataProvider`, body)

    static createAdmin = body => IdentityAPI.post('/api/Admin', body)

    static read = id => IdentityAPI.get(`/api/Admin/${id}`);

    static update = (id, item) => IdentityAPI.put(`/api/Admin/${id}`, item);

    static delete = id => IdentityAPI.delete(`/api/Admin/${id}`);

    static deleteAdminAvatar = id => IdentityAPI.delete(`/api/Admin/${id}/Avatar`)

    static resetAdminPass = id => IdentityAPI.post(`/api/Admin/${id}/ResetPassword`)

    static resendUserConfirmeMail = body => IdentityAPI.post(`/api/Admin/UserEmail`, body)

    // User
    static getUsersList = (body,lng) => IdentityAPI.post(`/api/Admin/User/DataProvider/${lng}`, body)

    static getUsersExportList = (body,lng) => IdentityAPI.post(`/api/Admin/User/ExportUsers/${lng}`, body)

    static getUser = id => IdentityAPI.get(`/api/Admin/User/${id}`);
    static getUserSettings = id => IdentityAPI.get(`/api/User/Settings/${id}`);

    static createUser = body => IdentityAPI.post('/api/Admin/User', body)

    static updateUser = (id, item) => IdentityAPI.put(`/api/Admin/User/${id}`, item)

    static deleteUser = id => IdentityAPI.delete(`/api/Admin/User/${id}`);

    static deleteUserAvatar = id => IdentityAPI.delete(`/api/Admin/User/${id}/Avatar`)

    static resetUserPass = (id,registrationMethod) => IdentityAPI.post(`/api/Admin/User/${id}/ResetPassword/${registrationMethod}`)

    static getUserAnalytic = (body,lng) => IdentityAPI.post(`/api/Analytic/User/DataProvider/${lng}`, body)

    static getUserAnalyticChartData = (scale, body) => IdentityAPI.post(`/api/Analytic/User/Chart/${scale}`, body)

    static getUsersAnalyticExportList = (body,lng) => IdentityAPI.post(`/api/Analytic/User/Export/${lng}`, body)
}
