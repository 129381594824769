// src/ability.js
import { Ability, AbilityBuilder } from "@casl/ability"
import Vue from 'vue'

// Define your initial ability (if any)
export const initialAbility = []

// Parse permission string into action and subject
const parsePermission = permission => {
    const action = permission.match(/[A-Z][a-z]*/g)[0].toLowerCase()
    const subject = permission.replace(action.charAt(0).toUpperCase() + action.slice(1), "")
    return { action, subject }
}

// Define abilities based on user permissions
export const defineAbilitiesFor = user => {
    const { can, rules } = new AbilityBuilder(Ability)
    const permissions = (user && user.permissions) || []

    permissions.forEach(permission => {
        const { action, subject } = parsePermission(permission)
        can(action, subject)
    })

    return rules
}

// Create a reactive Ability instance
const ability = new Ability(initialAbility)
const reactiveAbility = Vue.observable(ability)

// Function to set abilities based on user data
export const setAbilities = user => {
    const rules = defineAbilitiesFor(user)
    reactiveAbility.update(rules)
}

// Function to check permissions
export const can = (action, subject) => reactiveAbility.can(action, subject)

// Initialize abilities from localStorage (if any)
const userData = JSON.parse(localStorage.getItem("userData"))
if (userData) {
    setAbilities(userData)
}

export default reactiveAbility
