<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('buttons.addAdmin') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- First Name -->
          <validation-provider
            #default="validationContext"
            name="First Name"
            rules="required"
          >
            <b-form-group
              :label="`${ $t('fields.firstName') } *`"
              label-for="first-name"
            >
              <b-form-input
                :disabled="!can(ACTIONS.CREATE, SUBJECTS.ADMIN_USER)"
                id="first-name"
                v-model="userData.firstName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="John Doe"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Last Name -->
          <validation-provider
            #default="validationContext"
            name="Last Name"
            rules="required"
          >
            <b-form-group
              :label="`${ $t('fields.lastName') } *`"
              label-for="last-name"
            >
              <b-form-input
                id="last-name"
                :disabled="!can(ACTIONS.CREATE, SUBJECTS.ADMIN_USER)"
                v-model="userData.lastName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="John Doe"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              :label="`${ $t('fields.email') } *`"
              label-for="email"
            >
              <b-form-input
                id="email"
                :disabled="!can(ACTIONS.CREATE, SUBJECTS.ADMIN_USER)"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- User Role -->
          <validation-provider
            #default="validationContext"
            name="Role"
            rules="required"
          >
            <b-form-group
              :label="`${ $t('fields.role') } *`"
              label-for="user-role"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.role"
                :disabled="!can(ACTIONS.CREATE, SUBJECTS.ADMIN_USER)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-role"
              >
                <span slot="no-options">{{ $t('fields.noSelect') }}</span>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"

              variant="outline-secondary"
              class="mr-2"
              @click="hide"
            >
              {{ $t('buttons.cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              :disabled="!can(ACTIONS.CREATE, SUBJECTS.ADMIN_USER)"
            >
              {{ $t('buttons.create') }}
            </b-button>
          </div>
          <b-alert
            variant="danger"
            :show="errorMessages.active"
            class="mt-1"
          >
            <div class="alert-body">
              <feather-icon
                icon="InfoIcon"
                class="mr-50"
              />
              {{ errorMessages.text }}
            </div>
          </b-alert>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BAlert, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { useToast } from "vue-toastification/composition";

import store from '@/store'
import IdentityDataService from '@/service/IdentityDataService'
import { ACTIONS, SUBJECTS } from "@/libs/acl/config"
import { can } from "@/libs/acl/ability"

export default {
  components: {
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ToastificationContent,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      roleOptions: [],
    }
  },
  computed: {
    SUBJECTS() {
      return SUBJECTS
    },
    ACTIONS() {
      return ACTIONS
    },
  },
  methods: {
    can,
    separateWords(str) {
      return str.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
    },
  },
  setup(props, { emit }) {
    const blankUserData = {
      firstName: '',
      lastName: '',
      email: '',
      role: '',
    }
    const errorMessages = {
      active: false,
      text: '',
    }
    const toast = useToast()

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const errorMes = ref(JSON.parse(JSON.stringify(errorMessages)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      if (!can(ACTIONS.UPDATE, SUBJECTS.ADMIN_USER)) return
      IdentityDataService.createAdmin(userData.value).then(response => {
        if (response.data.accepted) {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active')
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              text: response.data.errorMessages[0],
              variant: 'danger',
            },
          })
        }
      })
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      errorMessages,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  created() {
    IdentityDataService.getRolesForCurrentUser({ applyHierarchyFilter:true}).then(response => {
      if (response.data.accepted) {

        this.roleOptions = response?.data?.data[0]?.items.map(role => ({
          id: role.id,
          value: role.name,
          text: this.separateWords(role.name),
          label: this.separateWords(role.name),
        }))
      }
    })
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
