export const initialAbility = [
  {
    action: 'read',
    subject: 'Auth',
  },
]

export const _ = undefined

export const ACTIONS = {
  VIEW: 'view',
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  PUBLISH: 'publish',
  PREVIEW: 'preview',
  EXPORT: 'export',
}

export const SUBJECTS = {
  ADMIN_USER_SECTION: "AdminUserSection",
  SUPER_ADMIN_USER: "SuperAdminUser",
  ADMIN_USER: "AdminUser",
  USER_SECTION: "UserSection",
  USER: "User",
  ARTICLE_SECTION: "ArticleSection",
  ARTICLE: "Article",
  ARTICLE_TRANSLATION: "ArticleTranslation",
  CONTENT_MANAGEMENT_SECTION: "ContentManagementSection",
  CLASSIFIER_SECTION: "ClassifierSection",
  CATEGORY_SECTION: "CategorySection",
  CATEGORY: "Category",
  CATEGORY_SPRITE_ICON: "CategorySpriteIcon",
  CATEGORY_TRANSLATION: "CategoryTranslation",
  SUBCATEGORY_SECTION: "SubcategorySection",
  SUBCATEGORY: "Subcategory",
  SUBCATEGORY_SPRITE_ICON: "SubcategorySpriteIcon",
  SUBCATEGORY_TRANSLATION: "SubcategoryTranslation",
  ARTICLE_ATTRIBUTE_SECTION: "ArticleAttributeSection",
  ARTICLE_ATTRIBUTE: "ArticleAttribute",
  ARTICLE_ATTRIBUTE_TRANSLATION: "ArticleAttributeTranslation",
  ARTICLE_TAG_SECTION: "ArticleTagSection",
  ARTICLE_TAG: "ArticleTag",
  ARTICLE_TAG_TRANSLATION: "ArticleTagTranslation",
  ARTICLE_HISTORICAL_PERIOD_SECTION: "ArticleHistoricalPeriodSection",
  ARTICLE_HISTORICAL_PERIOD: "ArticleHistoricalPeriod",
  ARTICLE_HISTORICAL_PERIOD_TRANSLATION: "ArticleHistoricalPeriodTranslation",
  ARTICLE_REGION_SECTION: "ArticleRegionSection",
  ARTICLE_REGION: "ArticleRegion",
  ARTICLE_REGION_TRANSLATION: "ArticleRegionTranslation",
  ARTICLE_COUNTRY_SECTION: "ArticleCountrySection",
  ARTICLE_COUNTRY: "ArticleCountry",
  ARTICLE_COUNTRY_TRANSLATION: "ArticleCountryTranslation",
  ARTICLE_HISTORICAL_COUNTRY_SECTION: "ArticleHistoricalCountrySection",
  ARTICLE_HISTORICAL_COUNTRY: "ArticleHistoricalCountry",
  ARTICLE_HISTORICAL_COUNTRY_TRANSLATION: "ArticleHistoricalCountryTranslation",
  EDUCATIONAL_CONTENT_SECTION: "EducationalContentSection",
  SCHOOL_COUNTRY_SECTION: "SchoolCountrySection",
  SCHOOL_COUNTRY: "SchoolCountry",
  SCHOOL_COUNTRY_TRANSLATION: "SchoolCountryTranslation",
  SCHOOL_STATE_SECTION: "SchoolStateSection",
  SCHOOL_STATE: "SchoolState",
  SCHOOL_STATE_TRANSLATION: "SchoolStateTranslation",
  SCHOOL_CITY_SECTION: "SchoolCitySection",
  SCHOOL_CITY: "SchoolCity",
  SCHOOL_CITY_TRANSLATION: "SchoolCityTranslation",
  SCHOOL_SECTION: "SchoolSection",
  SCHOOL: "School",
  SCHOOL_TRANSLATION: "SchoolTranslation",
  SUBJECT_SECTION: "SubjectSection",
  SUBJECT: "Subject",
  SUBJECT_SETTING_SECTION: "SubjectSettingSection",
  SUBJECT_SETTING: "SubjectSetting",
  SUBJECT_SETTING_TRANSLATION: "SubjectSettingTranslation",
  GRADE_SECTION: "GradeSection",
  GRADE: "Grade",
  TOPIC_LESSON_SECTION: "TopicLessonSection",
  TOPIC: "Topic",
  LESSON: "Lesson",
  LESSON_ARTICLE: "LessonArticle",
  ANALYTICS_SECTION: "AnalyticsSection",
  USER_ANALYTICS: "UserAnalytics",
  USER_ANALYTICS_LIST: "UserAnalyticsList",
  SCHOOL_ANALYTICS: "SchoolAnalytics",
  SCHOOL_ANALYTICS_LIST: "SchoolAnalyticsList",
  CUSTOM_TABLE_ANALYTICS: "CustomTableAnalytics",
  CUSTOM_TABLE_ANALYTICS_LIST: "CustomTableAnalyticsList",
  PRESENTATION_SECTION: "PresentationSection",
  PRESENTATION: "Presentation",
  GIS_EDITOR_SECTION: "GisEditorSection",
  ELEMENTS_SECTION: "ElementsSection",
  ELEMENTS_FROM_GIS_LIBRARY: "ElementsFromGISLibrary",
  ELEMENT: "Element",
  ELEMENT_TRANSLATION: "ElementTranslation",
  GROUP_SECTION: "GroupSection",
  GROUP: "Group",
  GROUP_TRANSLATION: "GroupTranslation",
  GIS_STYLE_EDITOR_SECTION: "GisStyleEditorSection",
  BASE_MAP_SECTION: "BaseMapSection",
  BASE_MAP_TAB: "BaseMapTab",
  BASE_MAP: "BaseMap",
  BASE_MAP_STYLES: "BaseMapStyles",
  BASE_MAP_GROUP_TAB: "BaseMapGroupTab",
  BASE_MAP_GROUP: "BaseMapGroup",
  BASE_MAP_GROUP_TRANSLATION: "BaseMapGroupTranslation",
  STYLE_TAB: "StyleTab",
  BASE_MAP_LAYER: "BaseMapLayer",
  SUBLEGEND_STATE_CONFIG: "SublegendStateConfig",
  LEGENDS_TAB: "LegendsTab",
  LEGEND: "Legend",
  LEGEND_TRANSLATION: "LegendTranslation",
  SUBLEGEND: "Sublegend",
  SUBLEGEND_TRANSLATION: "SublegendTranslation",
  SPRITE_ICON: "SpriteIcon",
  STYLE_CONFIGURATION_SECTION: "StyleConfigurationSection",
  MY_ACCOUNT: "MyAccount",
  BASE_MAP_LAYER_ORDER: "BaseMapLayerOrder",
}

export const routePermissions = {
  "/:lang/admins": ["ViewAdminUserSection"],
  "/:lang/admins/:id": ["ViewAdminUserSection"],
  "/:lang/users": ["ViewUserSection"],
  "/:lang/user/:id": ["ViewUserSection"],
  "/:lang/encyclopedia": ["ViewArticleSection"],
  "/:lang/presentations": ["ViewPresentationSection"],
  "/:lang/encyclopedia/new": ["ViewArticleSection"],
  "/:lang/encyclopedia/:id": ["ViewArticleSection"],
  "/:lang/cms/classifiers/category": ["ViewCategorySection"],
  "/:lang/cms/classifiers/category/sub-category/:id": ["ViewSubcategorySection"],
  "/:lang/cms/educational/country": ["ViewSchoolCountrySection"],
  "/:lang/cms/educational/subject": ["ViewSubjectSection"],
  "/:lang/cms/educational/subject/settings": ["ViewSubjectSettingSection"],
  "/:lang/cms/educational/grade": ["ViewGradeSection"],
  "/:lang/cms/educational/state": ["ViewSchoolStateSection"],
  "/:lang/cms/educational/city": ["ViewSchoolCitySection"],
  "/:lang/cms/educational/school": ["ViewSchoolSection"],
  "/:lang/analytics": ["ViewAnalyticsSection"],
  "/:lang/analytics/users": ["ViewUserAnalytics"],
  "/:lang/analytics/schools": ["ViewSchoolAnalytics"],
  "/:lang/analytics/tables": ["ViewCustomTableAnalytics"],
  "/:lang/topics": ["ViewTopicLessonSection"],
  "/:lang/gis/editor": ["ViewGisEditorSection"],
  "/:lang/gis/style-editor": ["ViewGisStyleEditorSection"],
  "/:lang/my-account": [], // No specific view permission needed
  "/:lang/pages/profile": [], // No specific view permission needed
  "/:lang/pages/faq": [], // No specific view permission needed
  "/:lang/pages/knowledge-base": [], // No specific view permission needed
  "/:lang/pages/knowledge-base/:category": [], // No specific view permission needed
  "/:lang/pages/knowledge-base/:category/:slug": [], // No specific view permission needed
  "/:lang/pages/blog/list": [], // No specific view permission needed
  "/:lang/pages/blog/:id": [], // No specific view permission needed
  "/:lang/login": [], // No specific view permission needed
  "*": [], // No specific view permission needed
}

// Function to revert any modified attributes or content
//   function revertChanges(mutations) {
//     // Use a setTimeout to process mutations in batches (non-blocking)
//     setTimeout(() => {
//       mutations.forEach(mutation => {
//         if (mutation.type === 'attributes') {
//           const target = mutation.target;
//           const attr = mutation.attributeName;
//
//           // Revert disabled and readonly attributes
//           if (attr === 'disabled' || attr === 'readonly') {
//             target.setAttribute(attr, true); // Force re-enable the attributes
//           }
//         }
//       });
//     }, 0); // 0ms delay to yield control back to the browser
//   }
//
//   // Create a new MutationObserver
//   const observer = new MutationObserver(revertChanges);
//
//   // Observe only specific types of elements
//   const elementsToObserve = document.querySelectorAll('input, button, textarea'); // Adjust this to observe only necessary elements
//   elementsToObserve.forEach(element => {
//     observer.observe(element, {
//       attributes: true,   // Detect attribute changes (disabled, readonly, etc.)
//       subtree: false      // Only observe the element itself, not its children
//     });
//   });
//
//   console.log('Optimized observer monitoring selected elements for changes.');
